import React from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Contact = () => {
  // const { isDark } = useSelector((store)=> store.darkMode);
  // const { isLanguage } = useSelector((store)=> store.languageMode);

  return <>
    <div className="contactContainer">
      <Link to='/'><button className='backBtn'>{"back"}</button></Link>  
      <h1>{  "I am not hard to find, let’s design and build something truly spectacular !"}</h1>
      <p>{ "I want to bring my collective design experience and interactive coding skills to the table where we can work together to solve real business problems in a way that optimizes all of our respective experience and knowledge."}</p>
      <p>{"Feel free to reach out any platforms below:"}</p>
      <section className="contactIcons">
        <a  href="mailto:yusufjon1503@gmail.com"  target="blank"><p>Email</p></a> 
        <a href="https://www.instagram.com/ysfjon/" target="blank"> <p>Instagram</p></a>
        <a href="https://www.linkedin.com/in/yusufjon-abdumajidov-51a733220/" target="blank"> <p>Linkedin</p></a>
        <a href="https://wa.me/+79895750338" class="fab fa-whatsapp" target="blank" ><p>WhatsApp</p></a>
      </section>
    </div>
  </>
}

export default Contact
