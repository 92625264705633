import React, {useState, useEffect} from 'react';
import Loader from './Loader';
// import {  useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import firstImage from '../assets/first.png';
import secondImage from '../assets/second.png';
import chatGPTimage from '../assets/chatGPT.png';
import megaKormImage from '../assets/megaKorm.png';
import eCommerceImage from '../assets/eCommerce.png';
import gymateImage from '../assets/gymate.png';
import rentCarImage from '../assets/carRental.png';
import keyboardImage from '../assets/keyboard.png';
import videoGenerator from '../assets/videoG.png';

const Projects = () => {

  const [ loading, setLoading ] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2700)
  }, [])

  return <>
  {
    loading ? <Loader /> :
 
      <div className={"projectsContainer"}>
      <Link to='/'><button className='backBtn'>{ "back"}</button></Link>  
        <h1>{"My past projects"}</h1>


        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>Video Generator App</h2>
            <p>{"Full-stack React Native app. This app generatos AI videos with prompts."}</p>
            <p>{"Technologies used:"} <span>JavaScript, React Native, Expo, Tailwind, Appwrite </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov/ai_app" target="blank"><span>Github</span></a>  
            </section>
          </section>
          <section className='projectImage'>
            <img src={videoGenerator} alt="chatGpt info" />
          </section>
        </div>

        
        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>Keyboard Accordion</h2>
            <p>{"Application build with React, Play the diatonic button accordion with your computer keyboard!."}</p>
            <p>{"Technologies used:"} <span>JavaScript, React, JSON, HTML, CSS, SVELTE  </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov/kyboardaccordion" target="blank"><span>Github</span></a>  
              <a href="https://kyboardaccordion-yusufjonabdumajidovs-projects.vercel.app//" target="blank"><span>Live</span></a>
            </section>
          </section>
          <section className='projectImage'>
            <img src={keyboardImage} alt="chatGpt info" />
          </section>
        </div>


        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>E-commerce</h2>
            <p>{"Application build with React and Redux, working e-commerce website with all real world functionalities. About all functionalities it is written on my GitHub account."}</p>
            <p>{"Technologies used:"} <span>JavaScript, React, Redux, JSON, CSS,  </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov/ecommerce-shop" target="blank"><span>Github</span></a>  
              <a href="https://ecommerce-shop-rho.vercel.app/" target="blank"><span>Live</span></a>
            </section>
          </section>
          <section className='projectImage'>
            <img src={eCommerceImage} alt="chatGpt info" />
          </section>
        </div>




        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>Car Rental</h2>
            <p>{"Website has all the soutions to the real world problems, included all the functionalities that modern day websites can have.  About all functionalities it is written on my GitHub account."}</p>
            <p>{ "Technologies used:"} <span>JavaScript, TypeScript, React, Redux, Figma, GraphQL, Appolo Client </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov" target="blank"><span>Github</span></a> 
              <a href="https://car-rental-service-liard.vercel.app/" target='blank'><span>Live</span></a> 
            </section>
          </section>
          <section className='projectImage'>
            <img src={rentCarImage} alt="" />
          </section>
        </div>



        


        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>Gymate</h2>
            <p>{"Gym website built for real world businesses. Gymate includes different functionalities which is useful for people who wants to get information about the gym. It has wide range of functionalities that has been solved many problems. About all functionalities it is written on my GitHub account."}</p>
            <p>{"Technologies used:"} <span>JavaScript, React, Redux, JSON, SCSS  </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov" target="blank"><span>Github</span></a> 
              <a href="https://gymate-six.vercel.app/" target='blank'>
                <span>Live</span>
              </a>
            </section>
          </section>
          <section className='projectImage'>
            <img src={gymateImage} alt="Helpo Info" />
          </section>
        </div>



        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>MegaKorm website</h2>
            <p>{ "This complex Application build for new agency that sells animal breeding and shows the locations of their shops.  It has wide range of functionalities that has been solved many problems. About all functionalities it is written on my GitHub account."}</p>
            <p>{"Technologies used:"} <span>JavaScript, React, Redux, JSON, SCSS, Figma  </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov/megaKorm" target="blank"><span>Github</span></a>  
              <a href="https://yusufjonabdumajidov.github.io/megaKorm/" target="blank"><span>Live</span></a>  
            </section>
          </section>
          <section className='projectImage'>
            <img src={megaKormImage} alt="megakorm info" />
          </section>
        </div>


      
       


        {/* Third project */}
      


        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>ChatGPT 2.0</h2>
            <p>{"Application build with the API of released chatGPT, used technologies React and Express, good experiment by working with the latest technologies. About all functionalities it is written on my GitHub account."}</p>
            <p>{"Technologies used:"} <span>JavaScript, Express, React, Redux, JSON, SCSS,  </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov/chatGPT-2.0" target="blank"><span>Github</span></a>   
            </section>
          </section>
          <section className='projectImage'>
            <img src={chatGPTimage} alt="chatGpt info" />
          </section>
        </div>


        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>Helpo</h2>
            <p>{"Application build for student needs. HELPO includes different functionalities which is useful for students, not only for studies as well as daily basis of people also. It has wide range of functionalities that has been solved many problems. About all functionalities it is written on my GitHub account."}</p>
            <p>{"Technologies used:"} <span>JavaScript, React, Redux, JSON, SCSS, Figma  </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov" target="blank"><span>Github</span></a> 
            </section>
          </section>
          <section className='projectImage'>
            <img src={firstImage} alt="Helpo Info" />
          </section>
        </div>



        <div className="projectContainer">
          <section className='projectInfo'>
            <h2>FoodHub</h2>
            <p>{"It is the perfect application for food delivery,       application shows restaurants near your location, and shows all the data about the restaurant. About all functionalities it is written on my GitHub account."}</p>
            <p>{"Technologies used:"} <span>JavaScript, React, Redux, Css, Figma, Adobe photoshop  </span> </p>
            <section>
              <a href="https://github.com/YusufjonAbdumajidov" target="blank"><span>Github</span></a>  
            </section>
          </section>
          <section className='projectImage'>
            <img src={secondImage} alt="" />
          </section>
        </div>
      </div>
       }
  </>
}

export default Projects