import React, { useState, useEffect } from 'react';
import Home from './components/Home';
import Loader from './components/Loader';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import { Routes, Route } from 'react-router-dom';

function App() {
  const [ loading, setLoading ] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500)
  }, [])

  


  return <>
    <div className="App">
      {/* { 
        loading ?  <Loader /> : <Home />
      } */}
      <Routes>
        { 
          loading ?   <Route path='/' element={ <Loader /> }/> : <Route path='/' element={ <Home /> }/>
        } 
        <Route path='/About' element={ <About /> }/>
        <Route path='/Projects' element={ <Projects /> }/>
        <Route path='/Contact' element={ <Contact /> }/>
      </Routes>
    </div>
    
    
  </>;
}

export default App;
