import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDark: false,
};

const darkModeSlice = createSlice({
    name: "darkMode",
    initialState,
    reducers: {
        switchDarkMode: (state, action) => {
            state.isDark = !state.isDark;
        },
    }
});

export const { openDarkMode, closeDarkMode, switchDarkMode } = darkModeSlice.actions;

export default darkModeSlice.reducer;