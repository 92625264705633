import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLanguage: false,
};

const languageModeSlice = createSlice({
    name: "languageMode",
    initialState,
    reducers: {
        switchLanguageMode: (state, action) => {
            state.isLanguage = !state.isLanguage;
        },
    }
});

export const {  switchLanguageMode } = languageModeSlice.actions;

export default languageModeSlice.reducer;