import React from 'react';
// import { IoColorFilterOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { switchDarkMode } from '../features/darkMode/darkModeSlice';
// import { Canvas } from '@react-three/fiber';
// import { OrbitControls, Stars } from '@react-three/drei';

// const Box = () => {
//     return <mesh>
//         <icosahedronGeometry />
//         <meshLambertMaterial radius='7' detail='2' attach="material" color="#6C63FF" />
//     </mesh>
// }



const Home = () => {

  return <>
   
    <div className='homeContainer'>
        <div className="mainHomeContainer">
            {  <h1>Crafting Dynamic Websites and Mobile Apps with <span>React</span> and  <span>React Native</span></h1>} 
            <nav>
                <ul>
                    <Link to='/About'>{<li>about</li>}</Link>
                    <Link to='/Projects'> <li>projects</li></Link>
                    <Link to='/Contact'>{<li>contact</li>}</Link> 
                </ul>
            </nav> 
        </div>
    </div>
    </>
}

export default Home