import React, {useState, useEffect} from 'react';
import personalImage from '../assets/personalImage.png';
import Loader from './Loader';
import { Link } from 'react-router-dom';
import resume from '../assets/resume.pdf';


const About = () => {

  const [ loading, setLoading ] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500)
  }, [])

  return <>
  {
    loading ? <Loader /> :
  
    <div className="aboutContainer">
        <Link to='/'><button className='backBtn'>back</button></Link>  
        <article>
        <h2>Hi, I’m Yusuf, a passionate developer specializing in creating dynamic and responsive websites and mobile applications using React and React Native. Let’s bring your ideas to life!</h2>
        <p>With over 1+ years of experience in web and mobile app development, I have honed my skills in React and React Native to deliver seamless and user-friendly digital solutions. My journey as a developer has been driven by a passion for innovation and a commitment to excellence. </p>

        <div className="links">
          <a className='resumeDownload' href={resume} download>My Resume</a>
          <Link to='/Projects'> <li>Projects</li></Link>
        </div>
        
        </article>
        <img src={personalImage} alt="my personal pic" />

        
    </div>
    }
  </>
}

export default About;